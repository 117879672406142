import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Theory from './pages/Theory';
import Danger from './pages/Danger';
import Law from './pages/Law';
import Prevention from './pages/Prevention';
import BannerInfo from './pages/BannerInfo';
import VictimSupport from './pages/VictimSupport';
import Footer from './components/Footer';

function App() {
  return (
    <Router>
      <div className="App">
        {/* 라우터로 각 페이지를 매핑 */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/theory" element={<Theory />} />
          <Route path="/danger" element={<Danger />} />
          <Route path="/law" element={<Law />} />
          <Route path="/prevention" element={<Prevention />} />
          <Route path="/banner-info" element={<BannerInfo />} />
          <Route path="/victim-support" element={<VictimSupport />} />
        </Routes>
        <Footer />  {/* Footer를 모든 페이지에 적용 */}
      </div>
    </Router>
  );
}

export default App;
