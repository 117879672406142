import React from 'react';
import './Theory.css';
import genderImg from '../assets/gender.png';

function Theory() {
  return (
    <div>
      <section className="Theory-content">
        <header className="Theory-header">
          <h1>성 이론</h1>
        </header>
        <h2>올바른 성 가치관</h2>
        <img src={genderImg} alt="올바른 성인지" className="Theory-image" />
        <p>
          올바른 성 가치관은 문화와 사회에 알맞는 성태도를 갖추는 것입니다. 이는 우리가 사회구성원으로서
          타인과의 관계에서 적절한 성 역할을 수행하고, 시대의 흐름에 맞춰 성 윤리를 준수하는 것을 의미합니다.
        </p>
        <ul>
          <li>성 사회화: 사회에서 기대되는 성 역할을 학습하는 과정</li>
          <li>성 윤리: 시대와 사회의 변화에 따라 성에 대한 올바른 가치관을 유지하는 것</li>
          <li>타인을 성적 대상화하지 않고, 인격체로 존중하는 태도</li>
        </ul>

        <h3>성 사회화란?</h3>
        <p>
          성 사회화는 개인이 아동기부터 청소년기, 그리고 성인기에 걸쳐 사회구성원으로서의 성 역할을 학습하는 과정입니다.
          이 과정은 가정, 학교, 사회에서 제공되는 성 교육과 문화적 경험을 통해 이루어집니다.
        </p>

        <h2>옳지 못한 성 가치관을 갖게 되는 이유</h2>
        <p>
          성 가치관이 왜곡되거나 건강하지 못하게 형성되는 이유는 주로 어린 시절의 경험과 환경, 교육의
          부재로 인해 발생합니다.
        </p>
        <ul>
          <li>어린 시절 신체적, 정신적 성적 학대 또는 피해</li>
          <li>성장 시기 애정을 받지 못하거나, 잔인한 환경에서 자람</li>
          <li>부모로부터의 사랑 부족</li>
          <li>가정, 학교, 사회에서의 올바르지 못한 성교육</li>
          <li>이른 나이에 접한 음란물로 인한 욕구의 비틀어짐</li>
        </ul>

        <h2>늘어나는 성범죄의 이유</h2>
        <p>
          현대 사회에서 성범죄가 증가하는 이유는 여러 요인들이 복합적으로 작용하고 있습니다. 특히 젠더기반
          폭력(GBV)과 디지털 성범죄가 큰 영향을 미치고 있으며, 이러한 문제들은 성교육의 부재와 성적 왜곡된
          인식에서 비롯됩니다.
        </p>
        <ul>
          <li>이른 나이에 시작되는 사춘기 및 어린 연령 대상 성교육의 부재</li>
          <li>불평등한 사회구조와 권력관계로 인한 젠더기반 폭력</li>
          <li>디지털 성범죄 등 쉽게 저지를 수 있는 성범죄의 증가</li>
          <li>세대 간 성교육 및 성인식의 차이로 인한 성폭력 범죄</li>
          <li>왜곡된 성의식 (성적 도착증, 몰카 등)</li>
        </ul>

        <h3>젠더기반 폭력 (GBV)</h3>
        <p>
          젠더기반 폭력(GBV)은 특정 성별을 대상으로 한 폭력으로, 사회적 불평등과 권력 구조 속에서 발생하는
          성범죄입니다. 특히, 디지털 성범죄가 증가하면서 누구나 가해자가 될 수 있는 상황이 발생하고 있습니다.
          자신도 모르게 이러한 범죄에 가담할 수 있음을 경각심을 가지고 인지해야 합니다.
        </p>
      </section>
    </div>
  );
}

export default Theory;
