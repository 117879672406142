import React from 'react';
import './VictimSupport.css';
import sunflowerCenterImg from '../assets/sunflowerCenter.jpg';
import ActImg from '../assets/act.jpg';
import Call1366Img from '../assets/1366.png';


function VictimSupport() {
  return (
    <div className="victim-support">
      <header className="victim-header">
        <h1>성범죄 피해자를 위한 제도</h1>
      </header>

      <section className="victim-content">
        <h2><a href='https://www.stop.or.kr/home/kor/M369584875/support/network/index.do'>해바라기 센터</a></h2>
        <img src={sunflowerCenterImg} alt="해바라기 센터" className="victim-image" />
        <p>
          전국에 위치한 해바라기 센터에서는 성폭력 피해자들에게 원스톱으로 상담과 신고를 제공합니다. 
          피해자는 신속하게 도움을 받을 수 있으며, 피해 접수 후 수사부터 재판 단계까지 전 과정을 지원받을 수 있습니다.
        </p>

        <h2>법률 지원</h2>
        <img src={ActImg} alt="법률상담" className="victim-image" />
        <p>
          수사 단계부터 재판 단계까지 피해자는 국선 변호인이 지정되어 법적 대리인을 통해 소송을 진행할 수 있습니다. 
          이를 통해 법적 절차를 혼자 감당하지 않도록 지원합니다.
        </p>

        <h2>의료 지원</h2>
        <p>
          성범죄 피해자는 신체적, 정신적 피해 회복을 위한 의료 지원을 받을 수 있습니다. 
          피해 후 신속한 치료와 회복이 중요하며, 이와 관련된 치료비와 심리 상담도 지원됩니다.
        </p>

        <h2 className='call-1366'><a href='https://seoul1366.or.kr/'>긴급 전화 1366</a></h2>
        <img src={Call1366Img} alt="1366" className="victim-image" />
        <p>
          피해자는 여성 긴급 전화 <a href='https://seoul1366.or.kr/'>1366</a>으로 24시간 도움을 요청할 수 있습니다. 
          피해자가 언제든지 접근할 수 있는 빠른 상담 서비스로, 피해 상황에 따라 즉각적인 대응이 가능합니다.
        </p>

        <h3>신고를 꺼리지 않도록 도입된 여러 제도</h3>
        <p>
          성폭력 피해를 당해도 신고를 망설이는 피해자를 위한 여러 제도가 도입되었습니다. 
          피해자의 안전을 최우선으로 보호하며, 신고 과정을 단순화하고 피해자의 익명을 보장하는 방안이 마련되어 있습니다.
        </p>
      </section>
    </div>
  );
}

export default VictimSupport;
