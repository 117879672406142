import React from 'react';
import './Danger.css';
import victimImg from '../assets/victim.jpg';

function Danger() {
  return (
    <div className="danger-container">
      {/* 헤더 섹션 */}
      <header className="danger-header">
        <h1>성범죄의 위험성</h1>
      </header>

      {/* 성범죄 피해자의 심리적 후유증 */}
      <section className="danger-section">
        <h2>성범죄 피해자의 심리적 후유증</h2>
        <img src={victimImg} alt="피해자" className="danger-image" />
        <p>
          성범죄 피해자는 심각한 심리적 후유증을 경험할 수 있으며, 그 영향은 장기적으로 지속될 수 있습니다. 
          피해자는 사건 이후 다음과 같은 심리적 증상을 겪을 수 있습니다.
        </p>
        <ul>
          <li><strong>불안 및 강박 증세</strong>: 사건 이후 피해자는 지속적인 불안감과 강박 증세를 느끼게 될 수 있으며, 이는 일상생활에 큰 지장을 줄 수 있습니다.</li>
          <li><strong>만성적 무력감 및 우울감</strong>: 피해자는 자신의 삶에 대한 통제력을 잃었다고 느끼며, 만성적인 무력감과 우울감을 경험할 수 있습니다.</li>
          <li><strong>죄책감, 수치심, 혐오심</strong>: 피해자는 자신을 탓하거나, 사건을 경험한 것에 대한 수치심, 그리고 타인에 대한 혐오감을 가질 수 있습니다.</li>
          <li><strong>대인관계 문제 및 신뢰 부족</strong>: 피해자는 타인과의 관계를 맺는 데 어려움을 겪고, 신뢰를 쌓는 데 큰 어려움을 느낄 수 있습니다.</li>
        </ul>
        <p>
          이러한 심리적 후유증을 극복하기 위해서는 꾸준한 상담과 치료가 필요합니다. 피해자가 안전하고 신뢰할 수 있는 환경에서 적절한 지원을 받을 수 있도록 해야 합니다.
        </p>
      </section>

      {/* 성범죄 가해자가 받게 되는 불이익 */}
      <section className="danger-section">
        <h2>성범죄 가해자가 받게 되는 불이익</h2>
        <p>
          성범죄 가해자는 법적 처벌을 포함하여 다양한 사회적 불이익을 받게 됩니다. 이로 인해 개인의 삶과 사회적 지위가 크게 영향을 받으며, 다음과 같은 불이익을 겪을 수 있습니다.
        </p>
        <ul>
          <li><strong>신상정보 등록 명령</strong>: 벌금 100만원 이상 시, 가해자는 신상정보등록 명령을 받게 됩니다. 이로 인해 신상 정보가 공개될 수 있으며, 취업이나 사회생활에 어려움을 겪을 수 있습니다.</li>
          <li><strong>공무원/공기업 결격사유</strong>: 성범죄 전과 기록이 있을 경우 공무원이나 공기업에 취업할 수 없으며, 기존의 공직도 박탈당할 수 있습니다.</li>
          <li><strong>사회복지기관 취업 제한</strong>: 성범죄 가해자는 사회복지기관에서 10년간 취업이 불가하며, 사회 복지 관련 활동에 참여할 수 없습니다.</li>
          <li><strong>해외 비자 발급 어려움</strong>: 성범죄 전과가 있는 경우 특정 국가에서는 비자 발급이 제한될 수 있으며, 해외 여행이나 이민이 어렵습니다.</li>
          <li><strong>신상정보 공개</strong>: 법원의 판결에 따라 성범죄자의 신상정보가 공개될 수 있으며, 이로 인해 사회적 낙인이 찍히고, 주변 환경에서도 차별과 배제를 경험할 수 있습니다.</li>
        </ul>
        <p>
          성범죄 가해자가 받는 불이익은 단순한 처벌을 넘어서 개인의 삶 전반에 걸쳐 큰 영향을 미칠 수 있습니다. 이러한 불이익을 통해 성범죄의 재발을 방지하고, 성범죄의 위험성을 경각심 있게 인식하게 하는 것이 중요합니다.
        </p>
      </section>

      {/* 성범죄 가해자 유형 */}
      <section className="danger-section">
        <h2>성범죄 가해자의 유형</h2>
        <p>성범죄 가해자는 다음과 같은 여러 유형으로 구분될 수 있습니다. 각 유형에 따라 범죄의 동기와 특징이 다릅니다.</p>
        <ul>
          <li><strong>우발적 성범죄자</strong>: 특정 순간의 충동에 의해 범죄를 저지르는 유형으로, 주로 20~30대의 젊은 층에서 많이 발생합니다.</li>
          <li><strong>기회주의적 성범죄자</strong>: 상황이 허락할 때 범죄를 저지르는 유형으로, 계획적이지 않고 기회를 노려 범죄를 저지릅니다.</li>
          <li><strong>가학성 성범죄자</strong>: 타인에게 고통을 주는 것에서 성적 만족을 느끼며, 심리적, 신체적으로 피해자에게 큰 상처를 남기는 유형입니다.</li>
          <li><strong>권력형 성범죄자</strong>: 권력이나 지위를 이용해 피해자를 통제하고, 성적 행위를 강요하는 유형으로, 사회적 지위가 높은 사람들에게서 주로 나타납니다.</li>
          <li><strong>분노형 성범죄자</strong>: 분노나 적대감에서 비롯된 성범죄로, 피해자에게 폭력적인 성행위를 강요하며 분노를 해소하려 합니다.</li>
          <li><strong>디지털 성범죄자</strong>: 불법촬영물, 아동 청소년 성착취물 제작 및 유포, 통신매체 이용 음란죄 등의 디지털 수단을 활용해 범죄를 저지르는 유형입니다.</li>
        </ul>
      </section>

      <footer className="danger-footer">
        <p>성범죄의 위험성을 경각심 있게 인식하고, 피해자와 가해자 모두에게 올바른 성 가치를 심어줄 수 있는 교육과 제도가 필요합니다.</p>
      </footer>
    </div>
  );
}

export default Danger;
