import React from 'react';
import './Prevention.css';
import preventionImg from '../assets/prevention.png';

function Prevention() {
  return (
    <div className="prevention-container">
      {/* 헤더 섹션 */}
      <header className="prevention-header">
        <h1>성범죄 재발 방지</h1>
      </header>

      {/* 성범죄 재발 방지를 위한 방법 섹션 */}
      <section className="prevention-section">
        <h2>성범죄 재발 방지를 위한 방법</h2>
        <img src={preventionImg} alt="재범방지" className="prevention-image" />
        <ul>
          <li><strong>일상에서 집중할 수 있는 무언가를 찾기</strong>: 취미나 운동과 같이 일상에서 성욕과는 다른 것에 집중할 수 있는 활동을 찾아 성범죄 재발 가능성을 낮춥니다.</li>
          <li><strong>나의 문제에 대해 공감하고 함께 노력해줄 사람 찾기</strong>: 상담사나 신뢰할 수 있는 지인과 함께 자신의 문제에 대해 이야기하고, 공감받으며 극복하려고 노력합니다.</li>
          <li><strong>성욕을 건강한 방법으로 풀기</strong>: 성욕을 불법적인 방법이 아닌, 건강한 방법으로 해소하는 법을 배워야 합니다.</li>
          <li><strong>약물치료 및 심리상담을 겁내지 않기</strong>: 필요한 경우, 전문 심리상담사와의 상담이나 약물치료를 받는 것을 두려워하지 않아야 합니다.</li>
        </ul>
      </section>

      {/* 성범죄 재발 방지를 위한 가치관 섹션 */}
      <section className="prevention-section">
        <h2>성범죄 재발 방지를 위한 노력 - 올바른 가치관 형성</h2>
        <p>올바른 성 가치관을 형성하는 것은 성범죄 재발 방지의 핵심 요소입니다. 다음과 같은 가치관을 습득하여 성범죄를 예방할 수 있습니다.</p>
        <ul>
          <li><strong>음란물 시청 자제 및 불법촬영물 시청하지 않기</strong>: 성도착 등 왜곡된 성적 만족을 유발하는 음란물 및 불법촬영물 시청을 자제해야 합니다.</li>
          <li><strong>성인지 관점에서 생각하기</strong>: 남녀의 사회적 위치가 대등하다고 생각하고, 성별에 따른 차별이나 불평등한 대우를 지양해야 합니다.</li>
          <li><strong>타인 또는 이성에 대한 혐오나 고정관념 제거</strong>: 타인에 대한 혐오나 고정관념을 갖지 않고, 성적 대상화가 아닌 인격체로서 존중해야 합니다.</li>
          <li><strong>상대방의 동의 없는 성적인 모든 행위가 불법임을 기억하기</strong>: 동의 없는 모든 성적 행위는 불법이라는 사실을 항상 인식하고, 타인의 권리를 존중하는 태도를 가져야 합니다.</li>
        </ul>
      </section>

      {/* 몰카 재범률 관련 섹션 */}
      <section className="prevention-section">
        <h2>몰카 범죄 재발 방지</h2>
        <p>
          디지털 성범죄 중 특히 몰래카메라 범죄는 재범률이 매우 높습니다. 이러한 범죄는 심각한 심리적 문제를 동반할 수 있으며, 
          재발을 막기 위해서는 심리상담과 약물치료 등 전문적인 치료가 필요합니다. 
          자신이 이러한 범죄를 저지르고 있음을 인지하고, 이를 고치려는 의지가 있어야만 재발을 막을 수 있습니다.
        </p>
      </section>

      <footer className="prevention-footer">
        <p>성범죄 재발 방지를 위한 노력은 꾸준한 교육과 올바른 가치관 형성에서 시작됩니다.</p>
      </footer>
    </div>
  );
}

export default Prevention;
