import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './BannerInfo.css';


const API_KEY = process.env.REACT_APP_YOUTUBE_API_KEY;
const SEARCH_QUERY = '성폭력 피해 예방';

function BannerInfo() {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    axios.get(`https://www.googleapis.com/youtube/v3/search`, {
      params: {
        part: 'snippet',
        q: SEARCH_QUERY,
        maxResults: 10, // 영상 개수
        key: API_KEY,
        type: 'video',
      }
    })
    .then(response => {
      setVideos(response.data.items);
    })
    .catch(error => {
      console.error('YouTube API 요청 중 오류 발생:', error);
    });
  }, []);

  return (
    <div>
      <h2>성폭력 예방 관련 유튜브 영상</h2>
      <div className="video-list">
        {videos.map(video => (
          <div key={video.id.videoId} className="video-item">
            {/* <p>{video.snippet.description}</p> */}
            <a href={`https://www.youtube.com/watch?v=${video.id.videoId}`} target="_blank" rel="noopener noreferrer">
              <img src={video.snippet.thumbnails.medium.url} alt={video.snippet.title} />
              <p>{video.snippet.title}</p>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}

export default BannerInfo;
