import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';

function Home() {
  return (
    <div>
      <header className="App-header">
        <h1>성범죄 예방 센터</h1>
        <nav>
          <Link to="/theory"><button>성 이론</button></Link>
          <Link to="/danger"><button>성범죄 위험성</button></Link>
          <Link to="/law"><button>성폭력처벌법</button></Link>
          <Link to="/prevention"><button>성범죄 재발 방지</button></Link>
        </nav>
      </header>

      <section className="banner">
        <h2>성범죄 없는 세상 만들기</h2>
        <Link to="/banner-info"><button>더 알아보기</button></Link>
      </section>

      <section className="banner2">
        <h2>성범죄 피해자를 위한 제도</h2>
        <Link to="/victim-support"><button>더 알아보기</button></Link>
      </section>
    </div>
  );
}

export default Home;
